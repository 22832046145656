import React, { FormEvent, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Dropdown from "../../Dropdowns/Dropdown";
import { fetchGearKitsState } from "../../../redux/reducers/gearKitsSlice";
import { setCharacterGear, setCharacterWeapons } from "../../../redux/reducers/characterSlice";


interface Props {
  closeModal: () => void
  gearState: GearState
  weaponsState: Weapon[]
  startingGear: StartingGearOptions
  option1: string
  option2: string
  option3: string
  optionBackpack: string
  setOption1: (value: string) => void
  setOption2: (value: string) => void
  setOption3: (value: string) => void
  setOptionBackpack: (value: string) => void
  setGearOrSilver: (value: string) => void
}

interface GearState {
  equipment: Gear[]
  armor: Gear[]
  tools: Gear[]
  instruments: Gear[]
  rations: Gear[]
}

export default function PickClassModal({ closeModal, gearState, weaponsState, startingGear, option1, option2, option3, optionBackpack, setOption1, setOption2, setOption3, setOptionBackpack, setGearOrSilver }: Props) {
  const dispatch = useAppDispatch();
  const gearKitsState: GearKit[] = useAppSelector(fetchGearKitsState);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const equipment1 = gearState.equipment.find((gear: Gear) => gear.name === option1);
    const tools1 = gearState.tools.find((gear: Gear) => gear.name === option1);
    const rations1 = gearState.rations.find((gear: Gear) => gear.name === option1);
    const instruments1 = gearState.instruments.find((gear: Gear) => gear.name === option1);
    const armor1 = gearState.armor.find((gear: Gear) => gear.name === option1);
    const weapon1 = weaponsState.find((weapon: Weapon) => weapon.name === option1);

    const equipment2 = gearState.equipment.find((gear: Gear) => gear.name === option2);
    const tools2 = gearState.tools.find((gear: Gear) => gear.name === option2);
    const rations2 = gearState.rations.find((gear: Gear) => gear.name === option2);
    const instruments2 = gearState.instruments.find((gear: Gear) => gear.name === option2);
    const armor2 = gearState.armor.find((gear: Gear) => gear.name === option2);
    const weapon2 = weaponsState.find((weapon: Weapon) => weapon.name === option2);

    const instruments3 = gearState.instruments.find((gear: Gear) => gear.name === option3);
    
    let backpackType: GearKit;
    gearKitsState.forEach((gear: GearKit) => {
      if (gear.name === optionBackpack) backpackType = gear;
    });
    
    const gearKits = [
      ...(gearState.equipment.filter((gear: Gear) => backpackType.content.some((item) => item.name === gear.name))).map((item) => {
        return {...item, qty: backpackType.content.find((i) => i.name === item.name).qty};
      }),
      ...(gearState.rations.filter((gear: Gear) => backpackType.content.some((item) => item.name === gear.name))).map((item) => {
        return {...item, qty: backpackType.content.find((i) => i.name === item.name).qty};
      })
    ];

    const newGear: Gear[] = [
      equipment1,
      tools1,
      rations1,
      instruments1,
      armor1,
      equipment2,
      tools2,
      rations2,
      instruments2,
      armor2,
      instruments3,
      ...gearKits
    ].filter((gear: any) => gear !== undefined);

    const newWeapons: Weapon[] = [
      weapon1,
      weapon2
    ].filter((weapon: any) => weapon !== undefined);

    dispatch(setCharacterGear(newGear));
    dispatch(setCharacterWeapons(newWeapons));
    closeModal();
  };


  return (
    <div className="starting-gear-modal">
      <form
        onSubmit={(e) => handleSubmit(e)}
      >
        <h2 className="level-up-modal__starting-gear--title">Pick Starting Gear</h2>
        <div className="level-up-modal__starting-gear">
          {/* Row 1 */}
          <Dropdown
            classData="level-up-modal__dropdown"
            emptyValueText={startingGear.row1[0].name}
            inputChange={(value: string) => setOption1(value)}
            value={{ index: option1, name: option1 }}
            options={[
              { index: startingGear.row1[0].name, name: startingGear.row1[0].name, subOptions: startingGear.row1[0].subOptions ? startingGear.row1[0].subOptions.map((gear: any) => {
                return { index: gear.name, name: gear.name };
              }) : null},
              startingGear.row1[1].name ? { index: startingGear.row1[1].name, name: startingGear.row1[1].name, subOptions: startingGear.row1[1].subOptions ? startingGear.row1[1].subOptions.map((gear: any) => {
                return { index: gear.name, name: gear.name };
              }) : null} : null,
            ]}
          />
          {/* Row 2 */}
          <Dropdown
            classData="level-up-modal__dropdown"
            emptyValueText={startingGear.row2[0].name}
            inputChange={(value: string) => setOption2(value)}
            value={{ index: option2, name: option2 }}
            options={[
              { index: startingGear.row2[0].name, name: startingGear.row2[0].name },
              { index: startingGear.row2[1].name, name: startingGear.row2[1].name },
            ]}
          />
          {/* Row 3 */}
          {startingGear.row3 &&
            <Dropdown
              classData="level-up-modal__dropdown"
              emptyValueText={startingGear.row3[0].name}
              inputChange={(value: string) => setOption3(value)}
              value={{ index: option3, name: option3 }}
              options={[
                { index: startingGear.row3[0].name, name: startingGear.row3[0].name, subOptions: gearState.instruments.map((gear: Gear) => {
                  return { index: gear.name, name: gear.name };
                })},
              ]}
            />
          }
          {/* Backpack */}
          <Dropdown
            classData="level-up-modal__dropdown"
            emptyValueText={gearKitsState[0].name}
            inputChange={(value: string) => setOptionBackpack(value)}
            value={{ index: optionBackpack, name: optionBackpack }}
            options={gearKitsState.map((gearKit: GearKit) => {
              return { index: gearKit.name, name: gearKit.name };
            })}
          />
          <div className="starting-gear-modal--bottom">
            <button type="button" onClick={() => setGearOrSilver('')}>
              <img
                src="/images/back_button.png"
                alt="back button"
              />
            </button>
            <button type="submit">
              <img
                src="/images/next_button.png"
                alt="next button"
              />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
