import React, { FormEvent } from "react";
import Dropdown from "../../Dropdowns/Dropdown";
import { useAppDispatch } from "../../../redux/hooks";
import { setCharacterTraits } from "../../../redux/reducers/characterSlice";


interface Props {
  nextPage: () => void
  goBack: () => void
  characterState: Character
  cultureTraitsState: CultureTrait[]
  trait1: CultureTrait
  trait2: CultureTrait
  trait3: CultureTrait
  setTrait1: (value: CultureTrait) => void
  setTrait2: (value: CultureTrait) => void
  setTrait3: (value: CultureTrait) => void
}

export default function PickCultureTraitsModal({ nextPage, goBack, characterState, cultureTraitsState, trait1, trait2, trait3, setTrait1, setTrait2, setTrait3 }: Props) {
  const dispatch = useAppDispatch();
  
  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    document.querySelector('.new-character-modal').parentElement.style.setProperty('max-width', '35rem');
    document.querySelector('.new-character-modal').parentElement.style.setProperty('max-height', '25rem');
    const cultureTraits = [trait1, trait2, trait3];
    dispatch(setCharacterTraits(cultureTraits));
    nextPage();
  };


  return (
    <div className="culture-triats-modal">
      <form
        onSubmit={(e) => handleSubmit(e)}
      >
        <h2>Pick Your Culture Traits</h2>
        <label>
          Positive Trait
          <Dropdown
            classData="level-up-modal__dropdown"
            emptyValueText={trait1.trait}
            inputChange={(value: string) => setTrait1(cultureTraitsState.find((trait: CultureTrait) => trait.id === Number(value)))}
            options={cultureTraitsState.filter((trait: CultureTrait) => trait.type === 'positive' && trait.culture === characterState.culture).map((trait: CultureTrait) => {
              return { index: `${trait.id}`, name: trait.trait };
            })}
          />
        </label>
        <label>
          Positive Trait
          <Dropdown
            classData="level-up-modal__dropdown"
            emptyValueText={trait2.trait}
            inputChange={(value: string) => setTrait2(cultureTraitsState.find((trait: CultureTrait) => trait.id === Number(value)))}
            options={cultureTraitsState.filter((trait: CultureTrait) => trait.type === 'positive' && trait.culture === characterState.culture).map((trait: CultureTrait) => {
              return { index: `${trait.id}`, name: trait.trait };
            })}
          />
        </label>
        <label>
          Negative Trait
          <Dropdown
            classData="level-up-modal__dropdown"
            emptyValueText={trait3.trait}
            inputChange={(value: string) => setTrait3(cultureTraitsState.find((trait: CultureTrait) => trait.id === Number(value)))}
            options={cultureTraitsState.filter((trait: CultureTrait) => trait.type === 'negative' && trait.culture === characterState.culture).map((trait: CultureTrait) => {
              return { index: `${trait.id}`, name: trait.trait };
            })}
          />
        </label>
        <div className="culture-triats-modal--bottom">
          <button type="button" onClick={goBack}>
            <img
              src="/images/back_button.png"
              alt="back button"
            />
          </button>
          <button type="submit">
            <img
              src="/images/next_button.png"
              alt="next button"
            />
          </button>
        </div>
      </form>
    </div>
  );
}
