import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState } from "../../../redux/reducers/characterSlice";


export default function SheetPerksBlock() {
  const characterState: Character = useAppSelector(fetchCharacterState);
  const [blankRows, setBlankRows] = useState([]);

  useEffect(() => {
    getBlankRows();
  }, [characterState]);

  // Get the amount of empty rows to render
  const getBlankRows = () => {
    const numOfBlankRows = 5 - characterState.perks.length;
    const emptyRows = [];
    for (let i = 0; i < numOfBlankRows; i++) {
      emptyRows.push([...blankRows, i]);
    }
    setBlankRows(emptyRows);
  };


  return (
    <div className="character-sheet__perks">
      {/* Perks header */}
      <div className="character-sheet__field character-sheet__perks-header">
        <p>Perks</p>
      </div>

      {/* Perks content */}
      {characterState.perks.map((perk: Perk, i) => {
        return (
          <React.Fragment key={i}>
            {/* "i + 2" is to put it under the title */}
            <div className={`character-sheet__field character-sheet__perks--name-${i + 2}`}>
              {perk.parent ?
                <p>Learn: { perk.desc }</p>
                :
                <p>{ perk.desc }</p>
              }
              {/* <button
                className="character-sheet__field character-sheet__perks--delete-btn"
                onClick={() => handleDeletePerk(perk)}
              >
                <img src="/images/trash.svg" alt="Trash can" draggable={false} />
              </button> */}
            </div>
          </React.Fragment>
        );
      })}

      {/* Blank rows */}
      {blankRows.map((row, i) => {
        const length = characterState.perks.length + 2;
        return (
          <React.Fragment key={i}>
            <div className={`character-sheet__field character-sheet__perks--name-${i + length}`}></div>
          </React.Fragment>
        );
      })}
    </div>
  );
}
