import React, { FormEvent } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { resetCharacterAbilities, setCharacterAwareness, setCharacterClass, setCharacterEducation, setCharacterPhysical, setCharacterRestDice, setCharacterSocial, setCharacterSp, setCharacterTechnical } from "../../../redux/reducers/characterSlice";
import { determineClassProf, determineClassStartingRestDice, determineClassStartingSilver } from "../../../scripts/characterSheetLogic";


interface Props {
  nextPage: () => void
  characterState: Character
}

export default function PickClassModal({ nextPage, characterState }: Props) {
  const dispatch = useAppDispatch();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    nextPage();
  };

  const handleChangeClass = (className: string) => {
    dispatch(setCharacterClass(className));
    // TODO: Add "Are you sure?" notification, because this will change
    // starting values based on class
    const restDice = determineClassStartingRestDice(className);
    dispatch(setCharacterRestDice(restDice));
    setProfAbiltiy(className);
  };

  const setProfAbiltiy = (className: string) => {
    dispatch(resetCharacterAbilities(''));
    switch(determineClassProf(className)) {
    case 'physical':
      dispatch(setCharacterPhysical({
        name: 'physical',
        value: characterState.education.value,
        prof: true
      }));
      break;
    case 'education':
      dispatch(setCharacterEducation({
        name: 'education',
        value: characterState.education.value,
        prof: true
      }));
      break;
    case 'technical':
      dispatch(setCharacterTechnical({
        name: 'technical',
        value: characterState.technical.value,
        prof: true
      }));
      break;
    case 'social':
      dispatch(setCharacterSocial({
        name: 'social',
        value: characterState.social.value,
        prof: true
      }));
      break;
    case 'awareness':
      dispatch(setCharacterAwareness({
        name: 'awareness',
        value: characterState.awareness.value,
        prof: true
      }));
      break;
    default:
      break;
    }
  };


  return (
    <div className="dropdown-modal">
      <form onSubmit={(e) => handleSubmit(e)}>
        <h2>Pick a class</h2>
        <select
          value={characterState.characterClass}
          onChange={(e) => handleChangeClass(e.target.value)}
          required
        >
          <option value="">-- PICK A CLASS --</option>
          <option value="warrior">WARRIOR</option>
          <option value="spy">SPY</option>
          <option value="musician">MUSICIAN</option>
          <option value="hunter">HUNTER</option>
          <option value="herbalist">HERBALIST</option>
          <option value="explorer">EXPLORER</option>
          <option value="brawler">BRAWLER</option>
          <option value="blacksmith">BLACKSMITH</option>
          <option value="beastmaster">BEASTMASTER</option>
        </select>
        <div className="dropdown-modal--bottom">
          <button type="submit">
            <img
              src="/images/next_button.png"
              alt="next button"
            />
          </button>
        </div>
      </form>
    </div>
  );
}
