import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import AccountBox from './AccountBox';
import { useAppSelector } from '../redux/hooks';
import { fetchUserState } from '../redux/reducers/userSlice';


export default function Navbar() {
  const userState: User = useAppSelector(fetchUserState);
  const smallDisplayRef = useRef(null);


  return (
    <nav className="navbar">
      <Link className="navbar__link navbar__link--home" to="/">
        <img src="/images/antediluvian_main_logo.png" alt="Company logo" draggable={false} />
      </Link>
      <AccountBox />
      {/* Account box links for smaller displays */}
      <div className="navbar__small-display" ref={smallDisplayRef}>
        <button
          className="navbar__small-display--close-btn"
          onClick={() => smallDisplayRef.current.classList.remove('navbar__small-display--open')}
        >
          X
        </button>
        <Link className="navbar__small-display--small-display-home" to="/">
          <img src="/images/antediluvian_main_logo.png" alt="Company logo" draggable={false} />
        </Link>
        <Link className="navbar__small-display--link" to="/account">ACCOUNT</Link>
        <Link className="navbar__small-display--link" to="/resources">RESOURCES</Link>
        <Link className="navbar__small-display--link" to="/characters">CHARACTERS</Link>
        <Link className="navbar__small-display--link" to="/vtt">PLAY ONLINE</Link>
      </div>

      {userState && userState.accesslevel === 2 && 
        <Link className="navbar__link navbar__link--admin" to="/admin">Admin</Link>
      }
      <button
        className="navbar__hamburger-btn"
        onClick={() => smallDisplayRef.current.classList.add('navbar__small-display--open')}>
        
        <img
          src="/images/hamburger-menu-btn.svg"
          alt="hamburger button for collapsed navbar"
        />
      </button>
    </nav>
  );
}
