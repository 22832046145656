import React, { FormEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState, setCharacterDivineRelics } from "../../../redux/reducers/characterSlice";


export default function SheetDivineRelicsBlock() {
  const dispatch = useAppDispatch();
  const characterState: Character = useAppSelector(fetchCharacterState);
  const [blankRows, setBlankRows] = useState([]);
  const [relicInput, setRelicInput] = useState('');
  
  useEffect(() => {
    getBlankRows();
  }, [characterState]);
  
  // Get the amount of empty rows to render
  const getBlankRows = () => {
    const numOfBlankRows = 7 - characterState.divineRelics.length;
    const emptyRows = [];
    for (let i = 0; i < numOfBlankRows; i++) {
      emptyRows.push([...blankRows, i]);
    }
    setBlankRows(emptyRows);
  };
  
  // Adds new relic to characterState
  const handleAddRelic = (e: FormEvent) => {
    e.preventDefault();
    if (!relicInput) return;
    dispatch(
      setCharacterDivineRelics([
        ...characterState.divineRelics,
        relicInput
      ])
    );
    setRelicInput('');
  };
  
  // Removes relic from characterState
  const handleDeleteRelic = (relic: string) => {
    dispatch(
      setCharacterDivineRelics(characterState.divineRelics.filter((_relic: string) => _relic !== relic))
    );
  };


  return (
    <div className="character-sheet__divine-relics">
      {/* Divine Relics header */}
      <div className="character-sheet__field character-sheet__divine-relics-header">
        <p>Divine Relics</p>
      </div>

      {/* Divine Relics content */}
      {characterState.divineRelics.map((relic: string, i) => {
        return (
          <React.Fragment key={i}>
            {/* "i + 2" is to put it under the title */}
            <div className={`character-sheet__field character-sheet__divine-relics--name-${i + 2}`}>
              <p>{ relic }</p>
              <button
                className="character-sheet__field character-sheet__divine-relics--delete-btn"
                onClick={() => handleDeleteRelic(relic)}
              >
                <img src="/images/trash.svg" alt="Trash can" draggable={false} />
              </button>
            </div>
          </React.Fragment>
        );
      })}

      {/* Blank rows */}
      {blankRows.map((row, i) => {
        const length = characterState.divineRelics.length + 2;
        return (
          <React.Fragment key={i}>
            {row === blankRows[0] ?
              <form
                className={`character-sheet__field character-sheet__divine-relics--name-${i + length} pdf-remove`}
                onSubmit={(e) => handleAddRelic(e)}
              >
                <input
                  placeholder="ADD DIVINE RELIC"
                  onChange={(e) => setRelicInput(e.target.value)}
                  value={relicInput}
                />
                <button type="submit">ADD</button>
              </form>
              :
              <div className={`character-sheet__field character-sheet__divine-relics--name-${i + length}`}></div>
            }
          </React.Fragment>
        );
      })}
    </div>
  );
}