import React, { useEffect, useRef } from 'react';
import Layout from '../components/Layout';
import { Prompt, useParams } from 'react-router-dom';
import SheetTopBlock from '../components/CharacterSheet/Page1/SheetTopBlock';
import { generatePDF } from '../scripts/tools/utils';
import { getCharacter, updateCharacter } from '../scripts/controllers/characterController';
import { fetchCharacterState, setCharacterData } from '../redux/reducers/characterSlice';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import SheetAbilityScoreBlock from '../components/CharacterSheet/Page1/SheetAbilityScoreBlock';
import SheetGearBlock from '../components/CharacterSheet/Page2/SheetGearBlock';
import SheetSkillsBlock from '../components/CharacterSheet/Page1/SheetSkillsBlock';
import SheetPerksBlock from '../components/CharacterSheet/Page1/SheetPerksBlock';
import SheetBlessingsBlock from '../components/CharacterSheet/Page1/SheetBlessingsBlock';
import SheetDivineRelicsBlock from '../components/CharacterSheet/Page1/SheetDivineRelicsBlock';
import SheetWeaponsBlock from '../components/CharacterSheet/Page1/SheetWeaponsBlock';
import SheetHealthBlock from '../components/CharacterSheet/Page1/SheetHealthBlock';
import SheetFeaturesBlock from '../components/CharacterSheet/Page1/SheetFeaturesBlock';
import SheetArmorBlock from '../components/CharacterSheet/Page1/SheetArmorBlock';
import SheetCompanionBlock from '../components/CharacterSheet/Page1/SheetCompanionBlock';
import SheetCharacterDetailsBlock from '../components/CharacterSheet/Page2/SheetCharacterDetailsBlock';
import SheetBackstoryBlock from '../components/CharacterSheet/Page2/SheetBackstoryBlock';
import SheetHeritageTraitsBlock from '../components/CharacterSheet/Page2/SheetHeritageTraitsBlock';
import SheetAlliesBlock from '../components/CharacterSheet/Page2/SheetAlliesBlock';
import SheetSpecialAmmoBlock from '../components/CharacterSheet/Page1/SheetSpecialAmmoBlock';

interface Params {
  characterId: string
}

export default function CharacterSheetPage() {
  const { characterId }: Params = useParams();
  const dispatch = useAppDispatch();
  const characterState = useAppSelector(fetchCharacterState);
  const levelUpBtn = useRef<HTMLButtonElement>(null);

  useEffect(() => {    
    const fetchData = async () => {
      const data: Character = await getCharacter(Number(characterId)) as Character;
      dispatch(
        setCharacterData({
          id: data.id,
          userId: data.userId,
          img: data.img,
          name: data.name,
          exhaustion: data.exhaustion,
          speed: data.speed,
          age: data.age,
          faithPoints: data.faithPoints,
          restDice: data.restDice,
          gender: data.gender,
          breathersTaken: data.breathersTaken,
          weaponExhaustionMod: data.weaponExhaustionMod,
          characterClass: data.characterClass,
          level: data.level,
          attackDice: data.attackDice,
          defenseDice: data.defenseDice,
          culture: data.culture,
          dailySlots: data.dailySlots,
          physical: data.physical,
          education: data.education,
          technical: data.technical,
          social: data.social,
          awareness: data.awareness,
          skills: data.skills,
          features: data.features,
          gp: data.gp,
          sp: data.sp,
          cp: data.cp,
          treasures: data.treasures,
          perks: data.perks,
          animalsOwned: data.animalsOwned,
          gear: data.gear,
          specializedAmmo: data.specializedAmmo,
          blessings: data.blessings,
          divineRelics: data.divineRelics,
          weapons: data.weapons,
          skin: data.skin,
          height: data.height,
          weight: data.weight,
          eyes: data.eyes,
          hair: data.hair,
          traits: data.traits,
          alliesAndContacts: data.alliesAndContacts,
          backstory: data.backstory,
          languages: data.languages,
          companion: data.companion,
        })
      );
    };
    fetchData();
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      event.returnValue = '';
    });
  }, []);

  // Exports character sheet as a PDF
  // Cannot happen if there are unsaved changes
  const handleExport = async () => {
    const page1: HTMLElement = document.getElementById('character-sheet-1')!;
    const page2: HTMLElement = document.getElementById('character-sheet-2')!;
    page1.classList.add('character-sheet--pdf');
    page2.classList.add('character-sheet--pdf');
    document.querySelectorAll('.pdf-remove').forEach((el: any) => el.innerHTML = '');
    await generatePDF([page1, page2]);
    page1.classList.remove('character-sheet--pdf');
    page2.classList.remove('character-sheet--pdf');
    location.reload();
  };

  // Update character data
  const saveCharacterData = async () => {
    playSaveAnimation();
    await updateCharacter({
      id: characterState.id,
      userId: characterState.userId,
      name: characterState.name,
      exhaustion: Number(characterState.exhaustion),
      speed: Number(characterState.speed),
      age: characterState.age,
      faithPoints: Number(characterState.faithPoints),
      restDice: JSON.stringify(characterState.restDice),
      gender: characterState.gender,
      breathersTaken: characterState.breathersTaken,
      weaponExhaustionMod: characterState.weaponExhaustionMod,
      characterClass: characterState.characterClass,
      level: characterState.level,
      attackDice: JSON.stringify(characterState.attackDice),
      defenseDice: JSON.stringify(characterState.defenseDice),
      culture: characterState.culture,
      dailySlots: characterState.dailySlots,
      physical: JSON.stringify(characterState.physical),
      education: JSON.stringify(characterState.education),
      technical: JSON.stringify(characterState.technical),
      social: JSON.stringify(characterState.social),
      awareness: JSON.stringify(characterState.awareness),
      skills: JSON.stringify(characterState.skills),
      gp: characterState.gp,
      sp: characterState.sp,
      cp: characterState.cp,
      treasures: characterState.treasures,
      animalsOwned: JSON.stringify(characterState.animalsOwned),
      divineRelics: JSON.stringify(characterState.divineRelics),
      skin: characterState.skin,
      height: characterState.height,
      weight: characterState.weight,
      eyes: characterState.eyes,
      hair: characterState.hair,
      traits: JSON.stringify(characterState.traits),
      alliesAndContacts: characterState.alliesAndContacts,
      backstory: characterState.backstory,
      languages: characterState.languages,
      companion: characterState.companion,
    },
    characterState.features,
    characterState.perks,
    characterState.gear,
    characterState.blessings,
    characterState.weapons,
    characterState.specializedAmmo
    );
  };

  const playSaveAnimation = () => {
    const el:HTMLElement = document.getElementById('saved-anim');
    el.classList.remove('saved-anim--active');
    setTimeout(() => el.classList.add('saved-anim--active'), 100);
  };


  return (
    <>
      <Layout>
        <Prompt
          when={true}
          message="Are you sure you want to leave this page?"
        />
        {/* PAGE 1 */}
        <div>
          <div className="character-sheet__top-btn-container">
            <button className="character-sheet__top-btn-container--save-btn" type="button" onClick={saveCharacterData}>Save</button>
            <button className="character-sheet__top-btn-container--export-btn" type="button" onClick={handleExport} disabled={false}>Export</button>
            <button className="character-sheet__top-btn-container--level-up-btn" type="button" ref={levelUpBtn}>Level Up</button>
          </div>
          {characterState.id ?
            <div className="character-sheet" id="character-sheet-1">
              <SheetTopBlock levelUpBtn={levelUpBtn.current} />
              <SheetHealthBlock />
              <div className="character-sheet__left-col">
                <SheetWeaponsBlock />
                <SheetArmorBlock />
                <div className="character-sheet__sub-col">
                  <SheetSkillsBlock />
                  <div style={{ 'display':'flex','flexDirection':'column','gap':'1.75rem' }}>
                    <SheetAbilityScoreBlock />
                    <SheetPerksBlock />
                    <SheetDivineRelicsBlock />
                  </div>
                </div>
                <SheetBlessingsBlock />
              </div>
              <div className="character-sheet__right-col">
                <SheetFeaturesBlock />
                <SheetCompanionBlock />
                <SheetSpecialAmmoBlock />
                {/* <img
                  className="character-sheet__logo"
                  src="/images/antediluvian_title_black.png"
                  alt='The word antediluvian in big blue text'
                /> */}
              </div>
            </div>
            :
            <h1></h1>
          }
        </div>

        {/* PAGE 2 */}
        <div>
          {characterState.id ?
            <div className="character-sheet character-sheet-2" id="character-sheet-2">
              <SheetGearBlock />
              <div className="character-sheet__left-col character-sheet-2--left-col">
                <SheetCharacterDetailsBlock />
                <SheetHeritageTraitsBlock />
                <SheetAlliesBlock />
              </div>
              <div className="character-sheet__right-col character-sheet-2--right-col">
                <SheetBackstoryBlock />
              </div>
            </div>
            :
            ''
          }
        </div>
      </Layout>
    </>
  );
}
