import React, { FormEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState, setCharacterFeatures } from "../../../redux/reducers/characterSlice";
import { getAllFeaturesByClass } from "../../../scripts/controllers/featuresController";


interface Props {
  index: string
}

export default function ClassFeaturesModal({ index }: Props) {
  const dispatch = useAppDispatch();
  const characterState: Character = useAppSelector(fetchCharacterState);
  const [featureDisplay, setFeatureDisplay] = useState<FeatureDisplay>();
  const [featureSelected, setFeatureSelected] = useState<Feature>();

  useEffect(() => {
    setFeatureDisplay(null);
    setFeatureSelected(null);
    fetchData();
  }, [characterState]);

  const fetchData = async () => {
    if (!characterState.id) return;
    const features: FeatureDisplay[] = await getAllFeaturesByClass(characterState.characterClass) as any;
    setFeatureDisplay(features.find((feature: FeatureDisplay) => feature.level === characterState.level));
  };

  const closeModal = () => {
    document.getElementById(`modal-container-${index}`).classList.add('hidden');
  };

  const handleAddFeature = (e: FormEvent) => {
    e.preventDefault();
    dispatch(
      setCharacterFeatures(
        [...characterState.features, featureSelected]
      )
    );
    
    setFeatureSelected(null);
    setFeatureDisplay(null);
    closeModal();
  };


  return (
    <div className="level-up-modal new-character-modal">
      <div className="level-up-modal features-modal feature-select-modal">
        {featureDisplay &&
          <>
            <h1>{ featureDisplay.title }</h1>
            <p>{ featureDisplay.desc }</p>
            <div className="feature-select-modal--bottom">
              <form onSubmit={(e) => handleAddFeature(e)}>
                <div className="features-modal__features-list">
                  {featureDisplay.featureList.map((feature: Feature, i) => {
                    return (
                      <div className="feature-select-modal--feature" key={i}>
                        <h2>{ i === 0 ? 'Trait of Battle' : 'Trait of Grace' }</h2>
                        <button
                          onClick={() => setFeatureSelected(feature)}
                          type="button"
                          className={`features-item${featureSelected && featureSelected.index === feature.index && ' features-item--selected'}`}
                        >
                          <h4 className="features-item__option-btn">{ feature.name }</h4>
                          <p>{ feature.desc }</p>
                        </button>
                      </div>
                    );
                  })}
                </div>
                <button type="submit">
                  <img
                    src="/images/next_button.png"
                    alt="next button"
                  />
                </button>
              </form>
            </div>
          </>
        }
      </div>
    </div>
  ); 
}
