import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


interface Props {
  character: Character
  deleteFn: (id: number) => void
  setActiveMenuId: (id: number) => void
  activeMenuId: number
  menuId: number
}

export default function CharacterCard({ character, deleteFn, setActiveMenuId, activeMenuId, menuId }: Props) {
  const { id, name, img } = character;
  const [menuOpen, setMenuOpen] = useState(false);
  const [pressedDeleteBtn, setPressedDeleteBtn] = useState(false);

  useEffect(() => {
    if (activeMenuId !== menuId) setMenuOpen(false);
  }, [activeMenuId]);

  const openCharacterMenu = () => {
    setMenuOpen(true);
    setActiveMenuId(menuId);
  };


  return (
    <div className="character-card-container">
      <div className="character-card" onClick={openCharacterMenu}>
        {!menuOpen && <h2 className="character-card__name">{ name }</h2>}
      </div>

      {/* Character card menu */}
      {menuOpen &&
        <div className="character-card-menu">
          <button
            className="character-card-menu__close-btn"
            onClick={() => setMenuOpen(false)}
          >
            <img
              src="/images/circle-left-regular.svg"
              alt="back arrow"
              draggable={false}
            />
          </button>
          {pressedDeleteBtn ?
            <>
              {/* Delete button confirm message */}
              <h2>Are you sure?</h2>
              <button
                className="character-card-menu__confirm-delete-btn"
                onClick={() => {
                  deleteFn(Number(id));
                  setPressedDeleteBtn(false);
                  setMenuOpen(false);
                }}
              >
                Yes
              </button>
              <button
                className="character-card-menu__cancel-btn"
                onClick={() => {
                  setPressedDeleteBtn(false);
                  setMenuOpen(false);
                }}
              >
                Cancel
              </button>
            </>
            :
            <>
              {/* Open and Delete buttons */}
              <Link className="character-card-menu__link" to={`/character-sheet/${id}`}>Open</Link>
              <button
                className="character-card-menu__delete-btn"
                onClick={() => setPressedDeleteBtn(true)}
              >
                Delete
              </button>
            </>
          }
        </div>
      }
    </div>
  );
}
