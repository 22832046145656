import React, { useState } from "react";
import { logout } from "../scripts/controllers/userController";
import Layout from "../components/Layout";
import { useAppSelector } from "../redux/hooks";
import { fetchUserState } from "../redux/reducers/userSlice";
import ProfilePicSelection from "../components/AccountPage/ProfilePicSelection";
import AccountPageInfoPanel from "../components/AccountPage/AccountPageInfoPanel";
import { Link } from "react-router-dom";


export default function AccountPage() {
  const userState: User = useAppSelector(fetchUserState);
  const [menuOpen, setMenuOpen] = useState(false);
  const [infoPanelState, setInfoPanelState] = useState('');

  const onFileInputClick = () => {
    setMenuOpen(!menuOpen);
  };


  return (
    <Layout>
      <div className="account-page">
        {menuOpen &&
          <ProfilePicSelection showMenu={setMenuOpen} />
        }
        <div className="account-page-top">
          <div className="account-page-top__profile-info">
            <div className="account-page-top__profile-info--pic-container">
              <img
                className="account-page-top__profile-info--profile-pic"
                src={userState.profilePic || "/images/character_card.png"}
                alt="profile pic"
                onClick={onFileInputClick}
                draggable={false}
              />
              <img
                className="account-page-top__profile-info--frame"
                src="/images/profie_pic_frame.png"
                alt="profile pic background"
                draggable={false}
              />
              <p className="account-page-top__profile-info--username">{ userState.username }</p>
            </div>
            <button className="account-page-top__profile-info--sign-out" onClick={logout}>Sign Out</button>
          </div>
          <div className="account-page-top__buttons">
            <button className="account-page-top__buttons--link" onClick={() => setInfoPanelState('characters')}>
              <img src="/images/account_btn_my_characters.png" alt="my characters button" draggable={false} />
            </button>
            <button className="account-page-top__buttons--link" onClick={() => setInfoPanelState('purchases')}>
              <img src="/images/account_btn_purchases.png" alt="purchases button" draggable={false} />
            </button>
            <Link className="account-page-top__buttons--link" to="/resources">
              <img src="/images/account_btn_shop.png" alt="shop button" draggable={false} />
            </Link>
          </div>
        </div>
        <AccountPageInfoPanel infoPanelState={infoPanelState} />
      </div>
    </Layout>
  );
}
