import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState, setCharacterGear } from "../../../redux/reducers/characterSlice";
import { fetchGearState } from "../../../redux/reducers/gearSlice";
import Dropdown from "../../Dropdowns/Dropdown";
import { getClassArmorUsed } from "../../../scripts/characterSheetLogic";


interface GearInterface {
  armor: Gear[]
}

export default function SheetArmorBlock() {
  const dispatch = useAppDispatch();
  const characterState: Character = useAppSelector(fetchCharacterState);
  const { armor }: GearInterface = useAppSelector(fetchGearState);
  const [blankRows, setBlankRows] = useState([]);
  const descBox: any = document.getElementById('desc-box');
  const descBoxText: any = document.querySelector('#desc-box p');
  
  useEffect(() => {
    getBlankRows();
  }, [characterState]);


  const showDescOnHover = (e: any, desc: string) => {
    descBox.classList.remove('hidden');
    descBox.style.top = `${e.pageY}px`;
    descBox.style.left = `${e.pageX + 60}px`;
    descBoxText.innerText = desc;
  };

  const showDescStopHover = (e: any) => {
    descBox.classList.add('hidden');
    descBoxText.innerText = '';
  };

  // Get the amount of empty rows to render
  const getBlankRows = () => {
    const numOfBlankRows = 3 - characterState.gear.filter((gear: Gear) => gear.type === 'armor').length;
    const emptyRows = [];
    for (let i = 0; i < numOfBlankRows; i++) {
      emptyRows.push([...blankRows, i]);
    }
    setBlankRows(emptyRows);
  };

  // Get item data from reducer
  const findArmor = (index: string): Gear => {
    return armor.find((armor: Gear) => armor.index === index);
  };

  const findCharacterAlreadyHasGear = (index: string): boolean => {
    return characterState.gear.some((gear: Gear) => gear.index === index);    
  };

  // Update gear reducer with new gear data
  // If the gear already exists then add 1 to the qty
  const handleAddNewGear = (index: string) => {
    // Check if GO BACK was selected
    if (index === 'back') return;

    const newArmor: Gear = findArmor(index);
    const CHARACTER_HAS_GEAR = findCharacterAlreadyHasGear(index);

    if (CHARACTER_HAS_GEAR) {
      const updatedCharacterState = characterState.gear.map((gear: Gear) => {
        if (gear.index === index) {
          return gear;
        }
        return gear;
      });
      dispatch(setCharacterGear(updatedCharacterState));
    } else {
      dispatch(setCharacterGear([...characterState.gear, newArmor]));
    }
  };

  const removeBlankRow = (index: string) => {
    const updatedCharacterState = characterState.gear.map((gear: Gear) => {
      if (gear.index === index) {
        return {
          id: gear.id,
          index: gear.index,
          name: gear.name,
          desc: gear.desc,
          lbs: gear.lbs,
          cost: gear.cost,
          type: gear.type,
          qty: gear.qty - 1,
          defense: gear.defense,
          carried: gear.carried
        };
      } else {
        return gear;
      }
    });
    dispatch(setCharacterGear(updatedCharacterState.filter((gear: Gear) => {
      return gear.qty > 0;
    })));
  };


  return (
    <div className="character-sheet__armor">
      {/* Gear header */}
      <div className="character-sheet__field character-sheet__armor-header character-sheet__armor-header--name-title">
        <p>Armor & Shields</p>
      </div>
      <div className="character-sheet__field character-sheet__armor-header character-sheet__armor-header--lbs-title">
        <p>Lbs</p>
      </div>
      <div className="character-sheet__field character-sheet__armor-header character-sheet__armor-header--def-title">
        <p>Def. Bns.</p>
      </div>

      {/* Gear content */}
      {characterState.gear.filter((gear: Gear) => gear.type === 'armor').map((gear: Gear, i) => {
        return (
          <React.Fragment key={i}>
            <div
              onMouseEnter={(e) => showDescOnHover(e, gear.desc)}
              onMouseLeave={(e) => showDescStopHover(e)}
              className={`character-sheet__field character-sheet__armor--name-${i + 2}`}
            >
              <p>{ gear.name }</p>
            </div>
            <div className={`character-sheet__field character-sheet__armor--lbs-${i + 2}`}>
              <p>{ gear.lbs }</p>
            </div>
            <div className={`character-sheet__field character-sheet__armor--def-${i + 2}`}>
              <p>+{ gear.defense }</p>
              <button
                className="character-sheet__armor--delete-btn"
                onClick={() => removeBlankRow(gear.index)}
              >
                <img src="/images/trash.svg" alt="Trash can" draggable={false} />
              </button>
            </div>
          </React.Fragment>
        );
      })}

      {/* Blank rows */}
      {blankRows.map((row, i) => {
        const armorAvailable = getClassArmorUsed(characterState.characterClass);
        const length = characterState.gear.filter((gear: Gear) => gear.type === 'armor').length + 2;
        return (
          <React.Fragment key={i}>
            {/* Checks if this is the first blank row */}
            {/* Makes the first blank row an input to add a new gear */}
            {row === blankRows[0] && armorAvailable ?
              <>
                <Dropdown
                  classData={`character-sheet__field character-sheet__armor--name-${i + length} pdf-remove`}
                  emptyValueText="-- ADD ARMOR --"
                  inputChange={(value: string) => handleAddNewGear(value)}
                  value={{ index: 'test', name: "-- ADD ARMOR --" }}
                  options={[
                    armorAvailable.light && { index: 'light-armor', name: 'LIGHT ARMOR', subOptions:
                      armor.filter((gear: Gear) => gear.subtype === 'light').map((gear: Gear) => {
                        return { index: gear.index, name: gear.name };
                      })
                    },
                    armorAvailable.medium && { index: 'medium-armor', name: 'MEDIUM ARMOR', subOptions:
                      armor.filter((gear: Gear) => gear.subtype === 'medium').map((gear: Gear) => {
                        return { index: gear.index, name: gear.name };
                      })
                    },
                    armorAvailable.heavy && { index: 'heavy-armor', name: 'HEAVY ARMOR', subOptions:
                      armor.filter((gear: Gear) => gear.subtype === 'heavy').map((gear: Gear) => {
                        return { index: gear.index, name: gear.name };
                      })
                    },
                    { index: 'shields', name: 'SHIELDS', subOptions:
                      armor.filter((gear: Gear) => gear.subtype === 'shield').map((gear: Gear) => {
                        return { index: gear.index, name: gear.name };
                      })
                    },
                  ]}
                />
                <div className={`character-sheet__field character-sheet__armor--lbs-${i + length}`}></div>
                <div className={`character-sheet__field character-sheet__armor--def-${i + length}`}></div>
              </>
              :
              <>
                <div className={`character-sheet__field character-sheet__armor--name-${i + length}`}></div>
                <div className={`character-sheet__field character-sheet__armor--lbs-${i + length}`}></div>
                <div className={`character-sheet__field character-sheet__armor--def-${i + length}`}></div>
              </>
            }
          </React.Fragment>
        );
      })}
    </div>
  );
}
