import React, { FormEvent } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { setCharacterCulture } from "../../../redux/reducers/characterSlice";


interface Props {
  nextPage: () => void
  goBack: () => void
  characterState: Character
  cultureTraitsState: CultureTrait[]
  setFeatureDisplay: (value: FeatureDisplay) => void
  setTrait1: (value: CultureTrait) => void
  setTrait2: (value: CultureTrait) => void
  setTrait3: (value: CultureTrait) => void
}

export default function PickCultureModal({ nextPage, goBack, characterState, cultureTraitsState, setFeatureDisplay, setTrait1, setTrait2, setTrait3 }: Props) {
  const dispatch = useAppDispatch();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    filterCultureTraitsState();
    document.querySelector('.new-character-modal').parentElement.style.setProperty('max-width', '50rem');
    document.querySelector('.new-character-modal').parentElement.style.setProperty('max-height', '30rem');
    nextPage();
  };

  const filterCultureTraitsState = () => {
    const cultureState = cultureTraitsState.filter((trait: CultureTrait) => {
      return trait.culture === characterState.culture;
    });
    setTrait1(cultureState[0]);
    setTrait2(cultureState[1]);
    setTrait3(cultureState.find((trait: CultureTrait) => trait.type === 'negative'));
  };


  return (
    <div className="dropdown-modal">
      <form onSubmit={(e) => handleSubmit(e)}>
        <h2>Pick a culture</h2>
        <select
          value={characterState.culture}
          onChange={(e) => dispatch(setCharacterCulture(e.target.value))}
          required
        >
          <option value="">-- PICK A CULTURE --</option>
          <option value="apsu">APSU</option>
          <option value="atar">ATAR</option>
          <option value="brahmi">BRAHMI</option>
          <option value="edennian">EDENNIAN</option>
          <option value="gavlo">GAVLO</option>
          <option value="mynocean">MYNOCEAN</option>
          <option value="nifilian">NIFILIAN</option>
          <option value="sasson">SASSON</option>
          <option value="sandlo">SANDLO</option>
          <option value="tepex">TEPEX</option>
          <option value="xi-an">XI&apos;AN</option>
        </select>
        <div className="dropdown-modal--bottom">
          <button type="button" onClick={goBack}>
            <img
              src="/images/back_button.png"
              alt="back button"
            />
          </button>
          <button type="submit">
            <img
              src="/images/next_button.png"
              alt="next button"
            />
          </button>
        </div>
      </form>
    </div>
  );
}
