import React from "react";
import { Link } from "react-router-dom";


export default function AccountBox() {
  return (
    <div className="account-box">
      <img src="/images/account_box.png" alt='Box with account information and links' />
      <Link className="account-box__link account-box__link--account" to="/account">ACCOUNT</Link>
      <Link className="account-box__link account-box__link--resources" to="/resources">RESOURCES</Link>
      <Link className="account-box__link account-box__link--characters" to="/characters">CHARACTERS</Link>
      <Link className="account-box__link account-box__link--vtt" to="/vtt">PLAY ONLINE</Link>
    </div>
  );
}
