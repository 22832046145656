import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import CharacterCard from '../components/CharacterCard';
import { addCharacter, deleteCharacter, getCharacters } from '../scripts/controllers/characterController';
import { useAppDispatch } from '../redux/hooks';
import HomeBottomGrid from '../components/HomeBottomGrid';
import { setCharacterData } from '../redux/reducers/characterSlice';
import { getPurchaseHistory } from '../scripts/controllers/shopController';
import { clamp } from '../scripts/tools/utils';


export default function CharactersPage() {
  const dispatch = useAppDispatch();
  const [characters, setCharacters] = useState<Character[]>([]);
  const [blankRows, setBlankRows] = useState([]);
  const [activeMenuId, setActiveMenuId] = useState(null);
  const [purchaseHistory, setPurchaseHistory] = useState<PurchasedProduct[]>([]);
  const startingSlots = 5;

  useEffect(() => {
    dispatch(setCharacterData({}));
    fetchCharacterData();

    const fetchData = async () => {  
      setPurchaseHistory(await getPurchaseHistory());
    };
    fetchData();
  }, []);

  const getBlankRows = (characters: Character[]) => {
    const numOfBlankRows = 16 - characters.length;
    setBlankRows(Array.from({ length: numOfBlankRows }, (_, i) => i));
  };

  const fetchCharacterData = async () => {
    const data: Character[] = await getCharacters() as any;
    setCharacters(data);
    getBlankRows(data);
  };

  const handleNewCharacter = async () => {
    await addCharacter();
    fetchCharacterData();
  };

  const handleDeleteCharacter = async (id: number) => {
    await deleteCharacter(id);
    fetchCharacterData();
  };

  const getSlotCount = () => {
    const prod = purchaseHistory.find((prod) => prod.product === 'prod_Q1QvsOaCG3Yf9C');
    return clamp(prod ? prod.qty - 1 + startingSlots : startingSlots, startingSlots, 16);
  };


  return (
    <>
      <Layout>
        <div className="characters-page">
          <div className="characters-page__left-col">
            <button className="new-character-btn">
              <img src="/images/comming_soon_shield.png" alt="Comming soon" />
            </button>
          </div>
          <div className="characters-page__featured-content-box">
            <img
              src="/images/feature_box.png"
              alt="Large box that displays featured content"
              draggable={false}
            />
            <div style={{"position":"absolute","top":"1rem"}}>
              <div className="characters-container">
                {/* Characters */}
                {characters.map((character, i) => {
                  return <CharacterCard character={character} deleteFn={handleDeleteCharacter} setActiveMenuId={setActiveMenuId} activeMenuId={activeMenuId} menuId={i} key={i} />;
                })}
                {/* Blank rows */}
                {blankRows.map((_, i) => {
                  const slotCount = getSlotCount();
                  if (characters.length + (i + 1) > slotCount) {
                    return <a key={i} className="character-card-container--locked" href="/resources"></a>;
                  } else {
                    return <button key={i} className="character-card-container--blank" onClick={handleNewCharacter}></button>;
                  }
                })}
              </div>
            </div>
          </div>
          <HomeBottomGrid />
        </div>
      </Layout>
    </>
  );
}
