import React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';
import { fetchFeaturedContentState } from '../redux/reducers/featuredContentSlice';
import Carousel from './Carousel';


export default function Header() {
  const { featureBoxImgArray }: FeaturedContent = useAppSelector(fetchFeaturedContentState);
  
  return (
    <header className="header">
      <div className="header__btn-col">
        <Link className="header__btn-col--link" to="/characters">
          <img src="/images/create_character_btn.png" alt="Create a character link" draggable={false} />
        </Link>

        <Link className="header__btn-col--link" to="resources">
          <img src="/images/resources_btn.png" alt="Resources link" draggable={false} />
        </Link>

        {/* <Link className="header__btn-col--link" to="subscription">
          <img src="/images/subscription_btn.png" alt="Subscription link" draggable={false} />
        </Link> */}
      </div>
      <div className="header__featured-content-container">
        {featureBoxImgArray.length > 1 ?
          <Carousel>
            {featureBoxImgArray.map((img: string, i) => {
              return (
                <div key={i}>
                  <img
                    className="header__featured-content-box header__featured-content-box--large"
                    src={img}
                    alt="Slide 1"
                    draggable={false}
                  />
                </div>
              );
            })}
          </Carousel>
          :
          <img
            className="header__featured-content-box header__featured-content-box--large"
            src={featureBoxImgArray[0] ? featureBoxImgArray[0] : '/images/feature_box.png'}
            alt="Large box that displays featured content"
            draggable={false}
          />          
        }

        {/* <img
          className="header__featured-content-border"
          src="/images/featured_box_top_border.png"
          alt="Featured box border"
          draggable={false}
        /> */}
      </div>
    </header>
  );
}
