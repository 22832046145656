import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fetchCharacterState, setCharacterSpecialAmmo } from "../../../redux/reducers/characterSlice";
import { fetchSpecializedAmmoState } from "../../../redux/reducers/specialAmmoSlice";
import { capitalize } from "../../../scripts/tools/stringUtils";
import Dropdown from "../../Dropdowns/Dropdown";


export default function SheetSpecialAmmoBlock() {
  const dispatch = useAppDispatch();
  const characterState: Character = useAppSelector(fetchCharacterState);
  const specializedAmmoState: SpecializedAmmo[] = useAppSelector(fetchSpecializedAmmoState);
  const [blankRows, setBlankRows] = useState([]);
  const descBox: any = document.getElementById('desc-box');
  const descBoxText: any = document.querySelector('#desc-box p');

  
  useEffect(() => {
    getBlankRows();
  }, [characterState, specializedAmmoState]);
  
  const getBlankRows = () => {
    const numOfBlankRows = 6 - characterState.specializedAmmo.length;
    const emptyRows = [];
    for (let i = 0; i < numOfBlankRows; i++) {
      emptyRows.push([...blankRows, i]);
    }
    setBlankRows(emptyRows);
  };

  const showDescOnHover = (e: any, desc: string) => {
    descBox.classList.remove('hidden');
    descBox.style.top = `${e.pageY}px`;
    descBox.style.left = `${e.pageX + 60}px`;
    descBoxText.innerText = desc;
  };

  const showDescStopHover = () => {
    descBox.classList.add('hidden');
    descBoxText.innerText = '';
  };

  const findAmmo = (id: number): SpecializedAmmo => {
    return specializedAmmoState.find((ammo: SpecializedAmmo) => ammo.id === id);
  };

  const findCharacterAlreadyHasAmmo = (id: number): boolean => {
    return characterState.specializedAmmo.some((ammo: SpecializedAmmo) => ammo.id === id);    
  };

  const handleAddNewAmmo = (id: number) => {
    const newAmmo: SpecializedAmmo = findAmmo(id);
    const CHARACTER_HAS_AMMO = findCharacterAlreadyHasAmmo(id);

    if (CHARACTER_HAS_AMMO) {
      const updatedAmmoState = characterState.specializedAmmo.map((ammo: SpecializedAmmo) => {
        if (ammo.id === id) {
          return {
            id: ammo.id,
            name: ammo.name,
            desc: ammo.desc,
            lbs: ammo.lbs,
            cost: ammo.cost,
            type: ammo.type,
            aoe: ammo.aoe,
            qty: ammo.qty + 1
          };
        }
        return ammo;
      });
      dispatch(setCharacterSpecialAmmo(updatedAmmoState));
    } else {
      dispatch(setCharacterSpecialAmmo([...characterState.specializedAmmo, { ...newAmmo, qty: 1 }]));
    }
  };

  const removeAmmoRow = (id: number) => {
    const updatedAmmoState = characterState.specializedAmmo.map((ammo: SpecializedAmmo) => {
      if (ammo.id === id) {
        return {
          id: ammo.id,
          name: ammo.name,
          desc: ammo.desc,
          lbs: ammo.lbs,
          cost: ammo.cost,
          type: ammo.type,
          aoe: ammo.aoe,
          qty: ammo.qty - 1
        };
      } else {
        return ammo;
      }
    });
    dispatch(setCharacterSpecialAmmo(updatedAmmoState.filter((ammo: SpecializedAmmo) => {
      return ammo.qty > 0;
    })));
  };


  return (
    <div className="character-sheet__special-ammo">
      {/* Ammo header */}
      <div className="character-sheet__field character-sheet__special-ammo-header character-sheet__special-ammo-header--name-title">
        <p>Specialized Ammo</p>
      </div>
      <div className="character-sheet__field character-sheet__special-ammo-header character-sheet__special-ammo-header--lbs-title">
        <p>Lbs</p>
      </div>
      <div className="character-sheet__field character-sheet__special-ammo-header character-sheet__special-ammo-header--qty-title">
        <p>Qty</p>
      </div>

      {/* Ammo content */}
      {characterState.specializedAmmo.map((ammo: SpecializedAmmo, i) => {
        return (
          <React.Fragment key={i}>
            <div
              onMouseEnter={(e) => showDescOnHover(e, ammo.desc)}
              onMouseLeave={(e) => showDescStopHover()}
              className={`character-sheet__field character-sheet__special-ammo--name-${i + 2}`}
            >
              <p>{ ammo.name } ({ capitalize(ammo.type) })</p>
            </div>
            <div className={`character-sheet__field character-sheet__special-ammo--lbs-${i + 2}`}>
              <p>{ ammo.lbs || 0 }</p>
            </div>
            <div className={`character-sheet__field character-sheet__special-ammo--qty-${i + 2}`}>
              <p>{ ammo.qty }</p>
              <button
                className="character-sheet__special-ammo--delete-btn"
                onClick={() => removeAmmoRow(ammo.id)}
              >
                <img src="/images/trash.svg" alt="Trash can" draggable={false} />
              </button>
            </div>
          </React.Fragment>
        );
      })}

      {/* Blank rows */}
      {blankRows.map((row, i) => {
        const length = characterState.specializedAmmo.length + 2;
        return (
          <React.Fragment key={i}>
            {row === blankRows[0] ?
              <>
                <Dropdown
                  classData={`character-sheet__field character-sheet__special-ammo--name-${i + length} pdf-remove`}
                  emptyValueText="-- ADD SPECIALIZED AMMO --"
                  inputChange={(value: string) => handleAddNewAmmo(Number(value))}
                  value={{ index: 'test', name: "-- ADD SPECIALIZED AMMO --" }}
                  options={[
                    { index: 'ammo', name: 'AMMO', subOptions:
                      specializedAmmoState.filter((ammo: SpecializedAmmo) => ammo.type === 'ammo').map((ammo: SpecializedAmmo) => {
                        return { index: `${ammo.id}`, name: ammo.name };
                      })
                    },
                    { index: 'explosive', name: 'GRENADES / EXPLOSIVES', subOptions:
                      specializedAmmoState.filter((ammo: SpecializedAmmo) => ammo.type === 'explosive').map((ammo: SpecializedAmmo) => {
                        return { index: `${ammo.id}`, name: ammo.name };
                      })
                    },
                    { index: 'concoction', name: 'CONCOCTIONS / ELIXERS', subOptions:
                      specializedAmmoState.filter((ammo: SpecializedAmmo) => ammo.type === 'concoction').map((ammo: SpecializedAmmo) => {
                        return { index: `${ammo.id}`, name: ammo.name };
                      })
                    }
                  ]}
                />
                <div className={`character-sheet__field character-sheet__special-ammo--lbs-${i + length}`}></div>
                <div className={`character-sheet__field character-sheet__special-ammo--qty-${i + length}`}></div>
              </>
              :
              <>
                <div className={`character-sheet__field character-sheet__special-ammo--name-${i + length}`}></div>
                <div className={`character-sheet__field character-sheet__special-ammo--lbs-${i + length}`}></div>
                <div className={`character-sheet__field character-sheet__special-ammo--qty-${i + length}`}></div>
              </>
            }
          </React.Fragment>
        );
      })}
    </div>
  );
}
